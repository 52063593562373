// @ts-nocheck
/* eslint-disable */

import React from 'react';

interface _Logo {
  color?: 'brand' | 'dark' | 'white' | 'black';
  mark?: boolean;
  href?: string;
}

export const Logo: React.FC<_Logo> = ({color, mark, href}) => {
  let finColor = 'var(--color-brand700)';
  if (color === 'brand') finColor = 'var(--color-brand700)';
  if (color === 'dark') finColor = 'var(--color-prussian700)';
  if (color === 'white') finColor = 'var(--color-white500)';
  if (color === 'black') finColor = 'var(--color-black500)';

  const logo = (
    <>
      {mark && <LogoMarkSvg color={finColor} />}
      {!mark && <LogoSvg color={finColor} />}
    </>
  );

  return (
    <>
      {href && <a href={href}>{logo}</a>}
      {!href && logo}
    </>
  );
};

interface _SvgProps {
  color?: string;
}

const LogoSvg: React.FC<_SvgProps> = ({color = 'var(--color-brand700)'}) => (
  <svg
    className="w-[164px] h-[25px]"
    width="164"
    height="25"
    viewBox="0 0 164 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M84.3888 0H86.8402V3.62374H84.3888C83.8204 3.62374 83.323 3.73033 82.8612 3.94348C82.3638 4.15664 81.973 4.40532 81.6177 4.7606C81.2625 5.11587 81.0138 5.50667 80.8006 5.9685C80.5875 6.46589 80.5164 6.96326 80.5164 7.46063V9.34356H84.2822V12.9318H80.5164V24.478H76.9282V7.46063C76.9282 6.46589 77.1058 5.50667 77.4966 4.58296C77.8874 3.65927 78.4203 2.87767 79.0953 2.20266C79.7703 1.52765 80.5519 0.994762 81.4756 0.60396C82.3638 0.213158 83.323 0 84.3888 0Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.3237 24.6911C66.4961 24.6911 67.6329 24.478 68.6987 24.0161C69.7645 23.5898 70.6882 22.9148 71.5409 21.9911C72.358 21.1385 72.9975 20.1437 73.4238 19.0424C73.8501 17.9766 74.0633 16.8042 74.0633 15.5608C74.0633 14.3528 73.8501 13.216 73.4238 12.1146C72.9975 11.0133 72.358 10.0186 71.5409 9.16592C70.6882 8.31329 69.7645 7.63827 68.6987 7.14088C67.6329 6.67905 66.4961 6.43035 65.3237 6.43035C64.1158 6.43035 63.0144 6.67905 61.9486 7.14088C60.8828 7.63827 59.9591 8.31329 59.142 9.16592C58.2894 10.0186 57.6499 11.0133 57.2236 12.1146C56.7972 13.216 56.5841 14.3528 56.5841 15.5608C56.5841 16.8042 56.7972 17.9766 57.2236 19.0424C57.6499 20.1437 58.2894 21.1385 59.142 21.9911C59.9591 22.8793 60.8828 23.5543 61.9486 24.0161C63.0144 24.478 64.1158 24.6911 65.3237 24.6911ZM65.3237 10.0186C66.0342 10.0186 66.6737 10.1962 67.3132 10.4804C67.9527 10.7646 68.4856 11.1554 68.9474 11.6528C69.4093 12.1502 69.7645 12.7541 70.0488 13.4292C70.333 14.1041 70.4751 14.8147 70.4751 15.5608C70.4751 16.3423 70.333 17.0884 70.0488 17.7634C69.7645 18.4384 69.4093 19.0068 68.9474 19.5042C68.4856 20.0016 67.9527 20.3924 67.3132 20.6766C66.6737 20.9608 66.0342 21.1029 65.3237 21.1029C64.6131 21.1029 63.9381 20.9608 63.2986 20.6766C62.6592 20.3924 62.1263 20.0016 61.6644 19.5042C61.2026 19.0068 60.8473 18.4384 60.5631 17.7634C60.2789 17.0884 60.1723 16.3423 60.1723 15.5608C60.1723 14.8147 60.2789 14.1041 60.5631 13.4292C60.8473 12.7541 61.2026 12.1502 61.6644 11.6528C62.1263 11.1554 62.6592 10.7646 63.2986 10.4804C63.9381 10.1962 64.6131 10.0186 65.3237 10.0186Z"
      fill={color}
    />
    <path
      d="M47.4054 24.6911C49.2883 24.6911 50.887 24.2293 52.166 23.2701C53.4805 22.2398 54.191 20.9963 54.2621 19.5042C54.3331 17.6568 53.3739 16.0937 51.4199 14.8147C51.0647 14.6015 50.6383 14.3528 50.1765 14.1397C50.0495 14.0811 49.9252 14.0251 49.8036 13.9704L49.8029 13.9701C49.4824 13.826 49.1807 13.6902 48.8975 13.5357L48.8805 13.5289L48.8212 13.5055C48.6432 13.4352 48.3883 13.3344 48.0804 13.1805C47.7251 13.0384 47.3343 12.8252 46.9435 12.612C46.5527 12.3989 46.1975 12.1857 45.9133 11.937C45.6291 11.7239 45.4869 11.4752 45.4869 11.2265C45.4869 10.9423 45.6646 10.6936 46.0554 10.4449C46.4106 10.2317 47.0146 10.0896 47.8672 10.0896C49.1107 10.1607 50.212 10.7291 51.1357 11.7949L54.1555 9.59223C53.3384 8.66855 52.4147 7.958 51.4199 7.38958C50.4252 6.82116 49.1817 6.53694 47.6896 6.46589C47.0146 6.46589 46.3396 6.57246 45.6646 6.78562C44.954 6.99878 44.3501 7.28301 43.7817 7.67379C43.2132 8.06459 42.7514 8.56196 42.3961 9.1304C42.0408 9.69883 41.8277 10.3738 41.8277 11.1554C41.7922 11.9015 41.9343 12.541 42.254 13.1094C42.5737 13.6778 43 14.1397 43.4974 14.566C43.9948 14.9923 44.5988 15.3831 45.2382 15.7384C45.8777 16.0937 46.4817 16.3779 47.1212 16.6621L48.4002 17.3016C48.7739 17.4884 49.0931 17.7026 49.3815 17.8963C49.4221 17.9235 49.4621 17.9503 49.5015 17.9766C49.8212 18.1897 50.0699 18.4384 50.2831 18.6516C50.4962 18.9003 50.6028 19.149 50.6028 19.3976C50.5318 19.8595 50.212 20.2503 49.6436 20.57C49.0396 20.8898 48.3291 21.0319 47.4764 21.0319C46.908 21.0319 46.3396 20.8898 45.8422 20.6056C45.3093 20.3569 44.8119 19.8595 44.3146 19.149L41.2592 21.0674C42.1119 22.3464 43.0711 23.2701 44.1369 23.803C45.1672 24.3359 46.2685 24.6201 47.4054 24.6911Z"
      fill={color}
    />
    <path
      d="M98.0811 20.8542H95.6297C95.0613 20.8542 94.5639 20.7832 94.1021 20.57C93.6047 20.3569 93.2139 20.1082 92.8586 19.7529C92.5034 19.3977 92.2547 19.0068 92.0415 18.545C91.8284 18.0832 91.7573 17.5858 91.7573 17.0529V10.3383H95.5232V6.71456H91.7573V0H88.1691V17.0529C88.1691 18.0832 88.3467 19.0424 88.7375 19.9306C89.1283 20.8542 89.6612 21.6358 90.3362 22.3109C91.0112 22.9858 91.7928 23.5188 92.7165 23.9096C93.6047 24.3004 94.5639 24.478 95.6297 24.478H98.0811V20.8542Z"
      fill={color}
    />
    <path
      d="M100.984 21.3161C101.375 20.8898 101.872 20.6766 102.44 20.6766C103.009 20.6766 103.506 20.8898 103.932 21.3161C104.359 21.7424 104.572 22.2398 104.572 22.8082C104.572 23.3767 104.359 23.874 103.932 24.2648C103.506 24.6911 103.009 24.8688 102.44 24.8688C101.872 24.8688 101.375 24.6911 100.984 24.2648C100.557 23.874 100.38 23.3767 100.38 22.8082C100.38 22.2398 100.557 21.7424 100.984 21.3161Z"
      fill={color}
    />
    <path
      d="M116.573 0H114.122C113.056 0 112.097 0.213158 111.209 0.60396C110.285 0.994762 109.503 1.52765 108.828 2.20266C108.153 2.87767 107.62 3.65927 107.23 4.58296C106.839 5.50667 106.661 6.46589 106.661 7.46063V24.478H110.249V12.9318H114.015V9.34356H110.249V7.46063C110.249 6.96326 110.32 6.46589 110.534 5.9685C110.747 5.50667 110.995 5.11587 111.351 4.7606C111.706 4.40532 112.097 4.15664 112.594 3.94348C113.056 3.73033 113.553 3.62374 114.122 3.62374H116.573V0Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130.495 6.71456H133.728V24.5135H130.495L130.318 22.8793C128.825 24.0872 127.085 24.6911 125.131 24.6911C123.994 24.6911 122.892 24.478 121.862 24.0161C120.832 23.5543 119.908 22.8793 119.127 21.9911C118.274 21.1385 117.67 20.1437 117.244 19.0424C116.817 17.9411 116.64 16.7687 116.64 15.5608C116.64 14.3528 116.853 13.216 117.279 12.1146C117.705 11.0133 118.309 10.0186 119.127 9.16592C119.908 8.31329 120.832 7.63827 121.862 7.14088C122.857 6.67905 123.958 6.43035 125.131 6.43035C126.019 6.43035 126.907 6.608 127.831 6.96326C128.754 7.31852 129.571 7.74484 130.282 8.27775C130.3 8.13529 130.318 8.00174 130.335 7.86819C130.353 7.7353 130.371 7.60238 130.389 7.46063C130.389 7.17642 130.424 6.92772 130.495 6.71456ZM123.177 20.6766C123.781 20.9608 124.42 21.1029 125.131 21.1029C125.806 21.1029 126.445 20.9608 127.085 20.6766C127.689 20.3924 128.221 20.0016 128.683 19.5042C129.11 19.0068 129.465 18.4384 129.749 17.7634C129.998 17.0884 130.14 16.3423 130.14 15.5608C130.14 14.8147 129.998 14.1041 129.749 13.4292C129.465 12.7541 129.11 12.1502 128.683 11.6528C128.221 11.1554 127.689 10.7646 127.085 10.4804C126.445 10.1962 125.806 10.0186 125.131 10.0186C124.42 10.0186 123.781 10.1962 123.177 10.4804C122.573 10.7646 122.04 11.1554 121.613 11.6528C121.152 12.1502 120.796 12.7541 120.548 13.4292C120.263 14.1041 120.157 14.8147 120.157 15.5608C120.157 16.3423 120.263 17.0884 120.548 17.7634C120.796 18.4384 121.152 19.0068 121.613 19.5042C122.04 20.0016 122.573 20.3924 123.177 20.6766Z"
      fill={color}
    />
    <path
      d="M151.698 21.778L149.211 19.22C148.642 19.824 148.003 20.2858 147.363 20.6056C146.724 20.9608 146.049 21.1029 145.374 21.1029C144.663 21.1029 144.024 20.9608 143.42 20.6766C142.781 20.3924 142.248 20.0016 141.786 19.5042C141.324 19.0068 140.969 18.4384 140.72 17.7634C140.436 17.0884 140.329 16.3423 140.329 15.5608C140.329 14.8147 140.436 14.1041 140.72 13.4292C140.969 12.7541 141.324 12.1502 141.786 11.6528C142.248 11.1554 142.781 10.7646 143.42 10.4804C144.024 10.1962 144.663 10.0186 145.374 10.0186C145.942 10.0186 146.546 10.1607 147.15 10.4449C147.754 10.7291 148.323 11.0844 148.891 11.5818L151.342 8.9883C150.419 8.17118 149.46 7.53168 148.465 7.10537C147.47 6.67905 146.44 6.43035 145.374 6.43035C144.237 6.43035 143.136 6.67905 142.105 7.14088C141.04 7.63827 140.116 8.31329 139.299 9.16592C138.517 10.0186 137.878 11.0133 137.451 12.1146C137.025 13.216 136.812 14.3528 136.812 15.5608C136.812 16.7687 137.025 17.9411 137.451 19.0424C137.878 20.1437 138.482 21.1385 139.299 21.9911C140.116 22.8793 141.04 23.5543 142.105 24.0161C143.136 24.478 144.237 24.6911 145.374 24.6911C146.511 24.6911 147.648 24.4425 148.713 23.9451C149.779 23.4477 150.774 22.7372 151.698 21.778Z"
      fill={color}
    />
    <path
      d="M161.549 20.8542H164V24.478H161.549C160.483 24.478 159.524 24.3004 158.635 23.9096C157.712 23.5188 156.93 22.9858 156.255 22.3109C155.58 21.6358 155.047 20.8542 154.656 19.9306C154.266 19.0424 154.088 18.0832 154.088 17.0529V0H157.676V6.71456H161.442V10.3383H157.676V17.0529C157.676 17.5858 157.747 18.0832 157.96 18.545C158.174 19.0068 158.422 19.3977 158.778 19.7529C159.133 20.1082 159.524 20.3569 160.021 20.57C160.483 20.7832 160.98 20.8542 161.549 20.8542Z"
      fill={color}
    />
    <path
      d="M15.8197 2.77849C15.7613 2.59142 15.7779 2.38893 15.8662 2.21393C15.9544 2.03892 16.1073 1.90516 16.2925 1.84095L20.2368 0.338151C20.367 0.289063 20.5068 0.271412 20.6451 0.286625C20.7834 0.301839 20.9161 0.349473 21.0324 0.425683L26.8978 4.30691C27.0578 4.41087 27.1747 4.56922 27.2271 4.7527L29.0141 11.2088L29.1167 11.6133C29.137 11.6929 29.1437 11.7753 29.1365 11.8572L29.1279 11.9545C29.1203 12.0408 29.0973 12.1249 29.0601 12.2031L23.9364 23.001C23.8594 23.1466 23.7419 23.2668 23.5981 23.3471C23.4543 23.4273 23.2903 23.4642 23.126 23.4533C22.9617 23.4423 22.804 23.384 22.6722 23.2854C22.5403 23.1867 22.4398 23.052 22.3828 22.8975L15.8197 2.77849Z"
      fill={color}
    />
    <path
      d="M22.2491 23.7929C22.4128 23.7805 22.5627 23.6348 22.4899 23.4474L18.9801 14.4163L14.6044 3.46117C14.5824 3.41041 14.546 3.36719 14.4998 3.33683C14.4536 3.30647 14.3995 3.29027 14.3442 3.29025C14.2889 3.29023 14.2348 3.30635 14.1886 3.33669C14.1562 3.3579 14.1287 3.38538 14.1076 3.41733C14.0985 3.43109 14.0905 3.44567 14.0839 3.46093L12.1568 8.00404C12.1246 8.07436 12.1077 8.15074 12.1072 8.22807C12.1067 8.30543 12.1227 8.38202 12.154 8.45274C12.1911 8.53291 12.2294 8.60912 12.2488 8.6407L18.1761 17.7794L11.5932 9.00839C11.5521 8.96122 11.5086 8.91631 11.4627 8.87378C11.3412 8.76379 11.184 8.70149 11.0202 8.69841L5.75752 8.46083C5.70319 8.45239 5.64756 8.4617 5.59889 8.48732C5.55023 8.51294 5.51112 8.55357 5.48731 8.60315C5.46351 8.65273 5.45632 8.70866 5.46677 8.76265C5.47723 8.81666 5.50477 8.86586 5.54535 8.90299L14.5295 17.075L21.9759 23.7295C22.0617 23.8062 22.1627 23.8193 22.2491 23.7929Z"
      fill={color}
    />
    <path
      d="M4.44484 9.81721C4.28583 9.69055 4.08761 9.62337 3.88434 9.62723C3.6811 9.63108 3.48557 9.70576 3.33149 9.83838L0.253434 12.5802C0.153923 12.6668 0.079424 12.7785 0.0377675 12.9037C-0.00391235 13.0289 -0.0112497 13.1629 0.0165291 13.2919L1.4505 19.8519C1.4926 20.0374 1.60079 20.2011 1.75487 20.3126L7.37521 24.4514L7.73104 24.7028C7.8019 24.7531 7.87996 24.7925 7.96257 24.8195L8.06173 24.8517C8.14957 24.8801 8.2413 24.8946 8.33361 24.8948L21.026 24.9234C21.7952 24.9251 22.1418 24.0324 21.5548 23.561L4.44484 9.81721Z"
      fill={color}
    />
  </svg>
);

const LogoMarkSvg: React.FC<_SvgProps> = ({color = 'var(--color-brand700)'}) => (
  <svg
    className="w-[30px] h-[25px]"
    width="30"
    height="25"
    viewBox="0 0 30 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8197 2.49716C15.7613 2.31009 15.7779 2.1076 15.8662 1.93261C15.9544 1.75759 16.1073 1.62383 16.2925 1.55962L20.2368 0.0568254C20.367 0.00773783 20.5068 -0.00991401 20.6451 0.00529985C20.7834 0.0205137 20.9161 0.0681479 21.0324 0.144358L26.8978 4.02558C27.0578 4.12955 27.1747 4.2879 27.2271 4.47138L29.0141 10.9274L29.1167 11.332C29.137 11.4116 29.1437 11.494 29.1365 11.5758L29.1279 11.6732C29.1203 11.7594 29.0973 11.8436 29.0601 11.9217L23.9364 22.7197C23.8594 22.8653 23.7419 22.9855 23.5981 23.0658C23.4543 23.146 23.2903 23.1829 23.126 23.172C22.9617 23.161 22.804 23.1027 22.6722 23.004C22.5403 22.9054 22.4398 22.7707 22.3828 22.6161L15.8197 2.49716Z"
      fill={color}
    />
    <path
      d="M22.2491 23.5115C22.4128 23.4992 22.5627 23.3534 22.4899 23.166L18.9801 14.135L14.6044 3.17984C14.5824 3.12909 14.546 3.08586 14.4998 3.0555C14.4536 3.02515 14.3995 3.00895 14.3442 3.00892C14.2889 3.0089 14.2348 3.02503 14.1886 3.05536C14.1562 3.07658 14.1287 3.10405 14.1076 3.136C14.0985 3.14976 14.0905 3.16434 14.0839 3.17961L12.1568 7.72271C12.1246 7.79304 12.1077 7.86941 12.1072 7.94675C12.1067 8.02411 12.1227 8.10069 12.154 8.17142C12.1911 8.25159 12.2294 8.3278 12.2488 8.35937L18.1761 17.4981L11.5932 8.72706C11.5521 8.6799 11.5086 8.63498 11.4627 8.59246C11.3412 8.48247 11.184 8.42016 11.0202 8.41709L5.75752 8.1795C5.70319 8.17106 5.64756 8.18037 5.59889 8.20599C5.55023 8.23162 5.51112 8.27224 5.48731 8.32182C5.46351 8.3714 5.45632 8.42733 5.46677 8.48132C5.47723 8.53533 5.50477 8.58453 5.54535 8.62167L14.5295 16.7937L21.9759 23.4482C22.0617 23.5248 22.1627 23.538 22.2491 23.5115Z"
      fill={color}
    />
    <path
      d="M4.44484 9.53588C4.28583 9.40922 4.08761 9.34204 3.88434 9.34591C3.6811 9.34975 3.48557 9.42444 3.33149 9.55705L0.253434 12.2988C0.153923 12.3854 0.079424 12.4972 0.0377675 12.6223C-0.00391234 12.7476 -0.0112497 12.8816 0.0165291 13.0106L1.4505 19.5706C1.4926 19.756 1.60079 19.9198 1.75487 20.0313L7.37521 24.17L7.73104 24.4215C7.8019 24.4718 7.87996 24.5112 7.96257 24.5382L8.06173 24.5703C8.14957 24.5988 8.2413 24.6133 8.33361 24.6134L21.026 24.642C21.7952 24.6438 22.1418 23.7511 21.5548 23.2797L4.44484 9.53588Z"
      fill={color}
    />
  </svg>
);
