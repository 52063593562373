// @ts-nocheck
/* eslint-disable */

import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {addCN, useClickOutOfBoundsHelper} from '@/_core/util/cssHelper';
import {useUiStore, zustandApi} from '@modules/Core/util/logic/zustand';
import {EnglishFlag, FrenchFlag, GermanFlag, ItalianFlag} from './Flags';

type _variants = Record<string, ReactElement>;

const variants: _variants = {
  en: <EnglishFlag />,
  de: <GermanFlag />,
};

interface _FlagDropDown {
  profileLang?: string;
  onLanguageChange?: (lang: string) => void;
}

// required for the click outside helper
const buttonId = 'FlagDropDown';

export const FlagDropDown: React.FC<_FlagDropDown> = ({profileLang, onLanguageChange}) => {
  const showFlagDropdown = useUiStore(state => state.showFlagDropdown);

  const [active, setActive] = useState<string | undefined>(profileLang);

  const variantsFiltered: _variants = useMemo(() => {
    if (!active) return variants;
    const {[active]: omitted, ...newVariants} = variants;
    return newVariants;
  }, [active]);

  useEffect(() => {
    setActive(profileLang);
  }, [profileLang]);

  const handleClickItem = (variant: string): void => {
    setActive(variant);
    zustandApi.updateUiStore({showFlagDropdown: false});
    onLanguageChange?.(variant);
  };

  const handleClickOutside = (): void => zustandApi.updateUiStore({showFlagDropdown: false});
  const ref = useClickOutOfBoundsHelper(handleClickOutside, buttonId);

  if (!active) return null;

  return (
    <div className="relative">
      <button
        id={buttonId}
        className={addCN(
          'cursor-pointer bg-white500 w-fit h-max px-0 py-1 rounded-t-xs transition',
          showFlagDropdown ? undefined : 'rounded-b-xs'
        )}
        onClick={() => zustandApi.handleShowExclusiveUi({showFlagDropdown: !showFlagDropdown})}
        dusk="language-switch"
      >
        {variants[active]}
      </button>

      <div
        ref={ref}
        className={addCN(
          'absolute left-[0px] bottom-[0px] pt-1 transition-all flex flex-col gap-1 bg-white500 rounded-b-xs z-50 shadow-3',
          showFlagDropdown ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'
        )}
        style={{transform: 'translate(0px, 100%)'}}
        dusk={`select-language-${active}`}
      >
        {Object.entries(variantsFiltered).map(([key, variant]) => (
          <button className="px-0 py-1" key={key} onClick={() => handleClickItem(key)}>
            {variant}
          </button>
        ))}
      </div>
    </div>
  );
};
