// @ts-nocheck
/* eslint-disable */

import React from 'react';

interface _Flags {
  flag: 'en' | 'fr' | 'it' | 'de';
}

export const Flags: React.FC<_Flags> = ({flag}) => {
  let finFlag = <EnglishFlag />;
  if (flag === 'en') finFlag = <EnglishFlag />;
  if (flag === 'fr') finFlag = <FrenchFlag />;
  if (flag === 'it') finFlag = <ItalianFlag />;
  if (flag === 'de') finFlag = <GermanFlag />;

  return <div className="flexCenterCenter transition w-4 h-4 rounded-xs bg-white500 hover:bg-grey100">{finFlag}</div>;
};

export const EnglishFlag: React.FC = () => (
  <svg
    className="w-[24px] h-[18px]"
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H24V18H0V0Z" fill="#012169" />
    <path
      d="M2.8125 0L11.9625 6.7875L21.075 0H24V2.325L15 9.0375L24 15.7125V18H21L12 11.2875L3.0375 18H0V15.75L8.9625 9.075L0 2.4V0H2.8125Z"
      fill="white"
    />
    <path
      d="M15.9 10.5375L24 16.5V18L13.8375 10.5375H15.9ZM9 11.2875L9.225 12.6L2.025 18H0L9 11.2875ZM24 0V0.1125L14.6625 7.1625L14.7375 5.5125L22.125 0H24ZM0 0L8.9625 6.6H6.7125L0 1.575V0Z"
      fill="#C8102E"
    />
    <path d="M9.0375 0V18H15.0375V0H9.0375ZM0 6V12H24V6H0Z" fill="white" />
    <path d="M0 7.2375V10.8375H24V7.2375H0ZM10.2375 0V18H13.8375V0H10.2375Z" fill="#C8102E" />
  </svg>
);

export const FrenchFlag: React.FC = () => (
  <svg
    className="w-[24px] h-[18px]"
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H24V18H0V0Z" fill="white" />
    <path d="M0 0H7.99875V18H0V0Z" fill="#002654" />
    <path d="M16.0012 0H24V18H16.0012V0Z" fill="#CE1126" />
  </svg>
);

export const GermanFlag: React.FC = () => (
  <svg
    className="w-[24px] h-[18px]"
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12H24V18H0V12Z" fill="#FFCE00" />
    <path d="M0 0H24V6H0V0Z" fill="black" />
    <path d="M0 6H24V12H0V6Z" fill="#DD0000" />
  </svg>
);

export const ItalianFlag: React.FC = () => (
  <svg
    className="w-[24px] h-[18px]"
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H24V18H0V0Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0H7.99875V18H0V0Z" fill="#009246" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.0012 0H24V18H16.0012V0Z" fill="#CE2B37" />
  </svg>
);
